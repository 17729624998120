import HS from './common'

// pusher-js/dist/web/pusher.js was copied into site/js/vendors/pusher-websocket-iso/pusher.js.
// We removed that vendored file in favor of using the NPM module.
// See: https://github.com/helpscout/hs-app/pull/9448
// If we import Pusher from 'pusher-js' we will not get the exact same code.
// Pusher.Runtime will be undefined. Importing the built script ensures we get
// the same code as the code we replaced, guaranteeing compatibility.
import Pusher from 'pusher-js/dist/web/pusher-with-encryption'

import 'pusher-js-auth'
function PusherClient() {
  // This file gets included on onboarding and other non-logged-in pages,
  // need to short-circuit in those cases
  if (!window.hsGlobal || !hsGlobal.pusher) return

  _.extend(this, Backbone.Events)
  this.initialize()
}

/**
 * Listen for channel subscription success/failure, calling callback at that time
 * @param channel
 * @param callback
 */
function onChannelSubscribeEnd(channel, callback) {
  var onSuccesOrFailure = function () {
    channel.unbind('pusher:subscription_succeeded', onSuccesOrFailure)
    channel.unbind('pusher:subscription_error', onSuccesOrFailure)
    callback()
  }
  channel.bind('pusher:subscription_succeeded', onSuccesOrFailure)
  channel.bind('pusher:subscription_error', onSuccesOrFailure)
}

PusherClient.prototype = {
  initialize: function () {
    var options = {
      auth: {
        headers: {
          'X-CSRF-Token': HS.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
      },
      authEndpoint: '/api/v0/pusher/auth',
      authTransport: 'buffered',
      authDelay: 1,
      cluster: hsGlobal.pusher.pusherCluster,
      encrypted: true,
      disableStats: true,
    }

    // Allow PusherClientSpec to override POST to GET for test purposes
    if (hsGlobal.pusher.authTransport) {
      options.authTransport = hsGlobal.pusher.authTransport
    }
    var pusher = new Pusher(hsGlobal.pusher.pusherAppKey, options)
    pusher.connection.bind('error', function (error) {
      var messageName = 'Pusher error'
      var extra = {
        error: error,
        channels: _.keys(pusher.channels.channels),
        state: pusher.connection.state,
      }
      var tags = {
        socketId: pusher.connection.socket_id,
      }

      var errorMessage =
        error && error.error && error.error.data && error.error.data.message
          ? error.error.data.message
          : ''
      if (errorMessage.indexOf('connection not subscribed to channel') !== -1) {
        messageName = 'Pusher publish while not subscribed'
        var channelName = error.error.data.message.match(/to channel (.*)\)/)[1]
        var channel = pusher.channel(channelName)
        extra.channel = channel ? true : undefined
      }

      if (errorMessage.indexOf('Expected HMAC') !== -1) {
        messageName = 'Pusher invalid auth signature'
        var auth = error.error.data.message.match(
          /Expected HMAC SHA256 hex digest of (.*), but got/
        )[1]
        tags.auth = auth
      }

      var isSocketError =
        error.error && error.error.data && error.error.data.code === 1006
      if (isSocketError) {
        // This is just a network connection error where socket connection fails - don't log it to Sentry
        console.log('socket error', error)
        return
      }
    })
    pusher.connection.bind('connected', function () {
      if (process.env.NODE_ENV !== 'test') {
        console.log('pusher connected!')
      }
    })
    this.pusher = pusher

    $(window).on('unload', _.bind(pusher.disconnect, pusher))
  },

  closeIfNoListeners: function (channel) {
    var callbacks = channel.callbacks
    var globalCallbacks = channel.global_callbacks
    var callbackCount =
      _.keys(callbacks._callbacks).length + _.keys(globalCallbacks).length
    if (callbackCount === 0) {
      // Only call unsubscribe if we're subscribed - or Pusher throws an error
      this.pusher.unsubscribe(channel.name)
      this.trigger('unsubscribed', channel.name)
      this.trigger('unsubscribed:' + channel.name, channel.name)
    }
  },

  subscribe: function (channelName, event, handler) {
    var channel = this.pusher.channel(channelName)
    if (!channel) {
      channel = this.pusher.subscribe(channelName)

      // Pusher does not expose a `subscribing` state - just subscribed true/false.
      // There is a pusher client bug where if you call `pusher.subscribe(name); pusher.unsubscribe(name);`
      // synchronously, the unsubscribe will fire first (yielding error), and then the subscribe will fire,
      // since the auth step causes the `pusher:subscribe` to be delayed, whereas the `pusher:unsubscribe`
      // fires immediately.
      // To get around this, we track `subscribing` state and
      channel.subscribing = true
      onChannelSubscribeEnd(channel, function () {
        delete channel.subscribing
      })
    }
    // Support subscribe('mailbox.id.whatever', handler) to bind _all_ events on that channel
    if (typeof event === 'function') {
      // Unbind first to avoid doubling up the same handler if subscribe is called multiple times!
      channel.unbind_global(event)
      channel.bind_global(event)
    } else {
      // Unbind first to avoid doubling up the same handler if subscribe is called multiple times!
      channel.unbind(event, handler)
      channel.bind(event, handler)
    }

    return channel
  },
  unsubscribe: function (channelName, eventName, handler) {
    var channel = this.pusher.channel(channelName)
    if (!channel) return

    if (!eventName || typeof eventName === 'function') {
      channel.unbind_all()
    } else {
      channel.unbind(eventName, handler)
    }
    // If the channel is mid-subscribe, avoid the bug referenced in `subscribe` by
    // waiting to unsubscribe until the channel subscription completes.
    if (channel.subscribing) {
      onChannelSubscribeEnd(
        channel,
        _.bind(function () {
          this.unsubscribe(channelName, eventName, handler)
        }, this)
      )
      return
    }

    this.closeIfNoListeners(channel)
  },

  publish: function (channelName, eventName, data) {
    var channel =
      this.pusher.channel(channelName) || this.pusher.subscribe(channelName)
    // Must subscribe to a channel before publishing events to it - ensure we're subscribed first
    if (channel.subscribed) {
      channel.trigger(eventName, data)
    } else {
      var onSubscribed = _.bind(function () {
        channel.trigger(eventName, data)
        // Only call it once
        channel.unbind('pusher:subscription_succeeded', onSubscribed)
        // It's possible the only reason we are subscribed to this channel is because of this publish call.
        // If that's the case, unsubscribe from it
        this.closeIfNoListeners(channel)
      }, this)
      channel.bind('pusher:subscription_succeeded', onSubscribed)
    }
  },
  /**
   * Pre-authorize a Pusher channel with bootstrapped, server-derived auth signature
   * This saves a Pusher auth request, gets us subscribed quicker.
   *
   * @param channelName
   * @param data
   */
  synchronousAuthorize: function (channelName, data) {
    var channel = this.pusher.channel(channelName)
    // If Pusher already has this channel, just short-circuit
    if (channel) return
    // Don't call pusher.subscribe(name) cuz this will double-subscribe.
    // Instead, add the new channel, then parse the auth data and manually call `send_event`
    channel = this.pusher.channels.add(channelName, this.pusher)

    var pusherData = {
      auth: data.auth,
      channel: channelName,
    }
    if (data.channel_data) {
      var channelData = JSON.parse(data.channel_data)
      channel.members.setMyID(channelData.user_id)
      pusherData.channel_data = data.channel_data
    }

    this.pusher.send_event('pusher:subscribe', pusherData)
  },

  /**
   * Get a fully qualified channel name for a Pusher channel
   *
   * @param {string} channel
   * @param {string} type - 'presence' or 'private'. Default is private
   * @return {string}
   */
  channelName: function (channel, type) {
    type = type || 'private'
    return type + '-' + hsGlobal.pusher.namespace + '.' + channel
  },
}

// In Karma, might get loaded twice - avoid conflicts by using the existing one
const pusherClient = window.PusherClient || new PusherClient()
window.PusherClient = pusherClient
export default pusherClient
